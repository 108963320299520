import React from "react";
import { Link } from "react-router-dom";
import { FaChevronRight, FaArrowRight } from "react-icons/fa";
import robot2 from "../images/1225.png";
import BannerRobot from "../images/home-robot-banner.png";
import DexLogo1 from "../images/Dexscreener.png";
import DexLogo2 from "../images/SafeAnalyzer.jpg";
import DexLogo3 from "../images/Maestro.jpg";
import DexLogo4 from "../images/GreekSwap.jpg";
import DexLogo5 from "../images/dextools-dark-logo.png";
import robot1 from "../images/explore-live.png";
import robot3 from "../images/unlock-real-time.png";
import tickIcon from "../images/correct-icon.svg"
import standingrobot from "../images/robot2.png"
import star1 from "../images/star1.png";
import star2 from "../images/star3.png";
import getstartedrobot from "../images/get-started-robot.png";
import contactaddress1 from "../images/contract-address-1.png"
import contactaddress2 from "../images/contract-address-2.png"
import contactaddress3 from "../images/contract-address-3.png"
import copyicon from "../images/copy-icon.png"
import { useEffect } from "react";

export const Home = () => {

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the browser is Telegram's in-app browser
    if (userAgent.includes("Telegram")) {
      // Suggest opening in another browser
      alert(
        "For a better experience, please open this website in an external browser."
      );

      // Redirect to the same URL in an external browser
      window.location.href = `intent://${window.location.host}${window.location.pathname}#Intent;scheme=https;end;`;
    }
  }, []);


  const handleCopy = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      alert("Contract Address Copied...")
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <div className="">
      {/* <div className="banner-section-outer position-relative"> */}

      {/* <!-- BANNER SECTION --> */}
      <section className="banner-section position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="banner-section-content">
                <h1 data-aos="fade-up" className="w-500">
                  Never Miss an <br /> Influencer Call with <br />{" "}
                  <span className="special-color">KolsAI</span>
                </h1>
                <div className="banner_content_wrapper position-relative">
                  <p className="text-white mb-0" data-aos="fade-right">
                    "Access trending tokens from 3000+ influencers,
                    <br /> set auto trades, and explore live DEX pairs – all{" "}
                    <br /> powered by AI."
                  </p>
                  <figure className="mb-0 position-absolute" style={{ height: "100%" }}>
                    <img
                      src="assets/images/banner_blue_line.png"
                      alt=""
                      className="img-fluid blue-line-height"
                    />
                  </figure>
                </div>
                <div
                  className="btn_wrapper aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <a className="text-decoration-none default-btn-two btn-size-18 hover-effect button-padding-2325"
                    href="https://app.uniswap.org/explore/tokens/ethereum/0xa60f743ff20afefa0a6b72d3f6fec075b3652598"
                    target="_blank"
                  >
                    Buy Now $KolsAI {" "}
                  </a>
                  <a
                    className="text-decoration-none default-btn btn-size-18 hover-effect readmore_btn"
                    href="https://app.kolsai.io/"
                    target="_blank" rel="noreferrer"
                  >
                    Try Beta Now <FaArrowRight />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="banner_image position-relative">
                <figure className="banner_main_image mb-0 left_right_shape">
                  <img
                    src={BannerRobot}
                    alt=""
                    className="banner-image-height"
                  />
                </figure>

              </div>
            </div>
          </div>

        </div>
      </section>

      <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
        <img
          src={star1}
          alt=""
          className="img-fluid w-50"
          style={{ opacity: 0.2 }}
        />
      </figure>
      <figure
        className="banner_right_shape mb-0 position-absolute"
        style={{ width: "24%" }}
      >
        <img
          src={star2}
          alt=""
          className="img-fluid"
          style={{ opacity: 0.2 }}
        />
      </figure>

      {/* <!-- Counter part --> */}
      <section className="aboutus_section position-relative pt-0 pb-30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <h2 className="real-time-heading">
                Real-Time Insights, Curated Daily
              </h2>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-30">
              <p className="real-time-number">10,000+</p>
              <p className="real-time-small-head">Meassages Analyzed Daily</p>
              <p className="real-time-text text-white">
                Every day, we scan and filter messages from top Key Opinion
                Leaders (KOLs) to bring you the most relevant insights.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-30 box-border-left">
              <p className="real-time-number">2,000+</p>
              <p className="real-time-small-head">
                New Tokens Monitored Across Leading Blockchains
              </p>
              <p className="real-time-text text-white">
                Our system continuously tracks tokens launched on Ethereum,
                Solana, Polygon, Sui, TON, and Tron networks.
              </p>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-50">
              <h2 className="Average-daily-heading">
                Average Daily Highlights
              </h2>
            </div>

            <div className="col-lg-12">
              <div className="row justify-content-center mb-50 mt-20" >
                <div className="col-lg-3 counter-box">
                  <div className="counter-heading">150+</div>
                  <div className="counter-text">New Tokens Featured by KOLs</div>
                </div>
                <div className="col-lg-3 counter-box">
                  <div className="counter-heading">1170+</div>
                  <div className="counter-text">Trending $Tokens Captured in Real-Time</div>
                </div>
                <div className="col-lg-3 counter-box">
                  <div className="counter-heading">50+</div>
                  <div className="counter-text">Hot Presale Opportunities Surfacing Dail</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECTION 1 --> */}
      <section className="aboutus_section position-relative pt-0 pb-50">
        <div className="container unlock-real-time-box">
          <div className="row align-items-center">


            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_image">
                <figure className="aboutus_main_image mb-0">
                  <img src={robot3} alt="" className="img-fluid" />
                </figure>

              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content" data-aos="fade-right">
                {/* <h6 className='text-center-mobile'>Overview of the Platform</h6> */}
                <h2 className="text-center-mobile unlock-heading">
                  {/* "Unlock Real-Time <span className="special-color">Token Trends</span> with Influencer Insights" */}
                  "Access Real-Time Influencer Insights from <span className="special-color">200+ Telegram </span>and <span className="special-color"> 10,000+ Twitter Accounts</span>"
                </h2>

                <ul className="list-unstyled mb-0">
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">
                      KolsAI gives you an all-encompassing view of the latest crypto trends by tracking real-time calls from a vast network of influencers across Telegram and Twitter.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">
                      Stay up-to-date on presale calls, trending tokens, and airdrops, allowing you to make prompt, informed trading decisions.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">Our centralized dashboard aggregates these signals, providing a continuous feed of market trends and influencer calls so you never miss important updates.</p>
                  </li>
                  {/* <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">Make informed decisions effortlessly on our platform.</p>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-50">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content" data-aos="fade-right">
                <div className="mb-20">
                  <h6 className="text-center-mobile">
                    AI-Enhanced Research Tools
                  </h6>
                </div>
                <h2 className="text-center-mobile unlock-heading">
                  Create a <span className="special-color">Customized Network</span> for <span className="special-color">Targeted</span> Influencer Insights
                </h2>

                <ul className="list-unstyled mb-0">
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">
                      With KolsAI, you can handpick and follow specific influencers to create a personalized network tailored to your interests and trading strategy.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">
                      Get real-time notifications and updates from your chosen influencers, allowing you to focus on the calls and insights most relevant to you.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">This feature empowers you to manage your own influencer network, ensuring that your feed is filled with curated, high-value signals aligned with your unique trading goals.</p>
                  </li>
                  {/* <li>
                    <img src={tickIcon} alt="" />
                    <p className="different-text-color-1">
                      Stay ahead in the crypto world with all data in one place.
                    </p>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_image">
                <figure className="aboutus_main_image mb-0">
                  <img src={robot1} alt="" className="img-fluid" />
                </figure>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Investors Tools --> */}
      <section className="aboutus_section position-relative pt-0">
        <div className="container ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-30">
            <h2 className="real-time-heading">Most <span className="special-color">powerfull</span>  tool for investor</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content powerfull-container" data-aos="fade-right">
                {/* <h6 className='text-center-mobile'>Overview of the Platform</h6> */}
                {/*<div className="mb-20">
                    <h5 className="text-center-mobile powerfull-heading">
                    For Influencers
                    </h5>
                </div>*/}
                <h2 className="text-center-mobile unlock-heading">
                  Stay Ahead with Real-Time Market and Influencer Insights
                </h2>

                <ul className="list-unstyled mb-0">
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Track influencer signals for timely crypto insights.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Analyze market trends and token behaviors quickly.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Receive instant updates on new DEX pair launches.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Follow top traders in real time to copy their strategies.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content powerfull-container" data-aos="fade-right">
                {/* <h6 className='text-center-mobile'>Overview of the Platform</h6> */}
                {/* <div className="mb-20">
                    <h5 className="text-center-mobile powerfull-heading">
                    Automated Trading Features
                    </h5>
                </div>*/}
                <h2 className="text-center-mobile unlock-heading">
                  AI-Driven Auto Buy <br /> and Sell
                </h2>

                <ul className="list-unstyled mb-0">
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Automate trades based on influencer-shared tokens.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Set criteria like ownership status and LP burns.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>No need to manually track every launch or update.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Let AI manage trades while you focus on strategy.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20 mt-30">
            <h2 className="real-time-heading"><span className="special-color">Get Paid </span> as an Influencer</h2>
          </div>
          <div className="row align-items-center mt-50">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content powerfull-container-2" data-aos="fade-right">

                <h2 className="text-center-mobile unlock-heading">
                  Monetize Your <span className="special-color">Influence</span>
                </h2>

                <ul className="list-unstyled mb-0">
                  <li className="">
                    {/* <img src={tickIcon} alt="" /> */}
                    <img src={tickIcon} alt="" />
                    <p>
                      Earn a share of transaction fees.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Drive value to your community.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Gain from trades inspired by your calls.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Increase earnings with successful calls.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content powerfull-container-2" data-aos="fade-right">

                <h2 className="text-center-mobile unlock-heading">
                  Ready to <span className="special-color">Dive In?</span>
                </h2>

                <ul className="list-unstyled mb-0">
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Get more visibility with KolsAI’s active crypto user base.
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Let users track and interact with your calls in real time.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Monetize your insights with future rewards.</p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>Establish credibility as a key voice in the crypto space.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECTION 3 4--> */}
      <section className="who_we_are_section position-relative">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
              <div className="who_we_are_left_content" data-aos="fade-up">
                <img src={standingrobot} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12"></div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="who_we_are_left_content" data-aos="fade-up">
                <h6 className="powerfull-heading">Coming soon KolsAI Chatbot</h6>
                <h2 className="participate-heading">Get Instant Answers with the KolsAI Chatbot</h2>

                <div className="aboutus_content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <img src={tickIcon} alt="" />
                      <p><span className="special-color">Instant Insights:</span>Ask about trending tokens, presales, and signals.</p>
                    </li>
                    <li>
                      <img src={tickIcon} alt="" />
                      <p><span className="special-color">Quick Data Access:</span>Get info on tokens, contracts, and prices instantly.</p>
                    </li>
                    <li>
                      <img src={tickIcon} alt="" />
                      <p><span className="special-color">24/7 Support:</span>Stay informed with round-the-clock assistance.</p>
                    </li>
                    <li>
                      <img src={tickIcon} alt="" />
                      <p><span className="special-color">Easy Navigation:</span>Effortlessly access insights and market data.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus_section position-relative pt-0">
        <div className="container-fluid">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-60">
            <h2 className="tokonoimcs-heading mb-40">
              TOKENOMICS
            </h2>
          </div>

          <div className="col-lg-12">
            <div className="row justify-content-center mb-50 mt-20" >
              <div className="col-lg-3">
                <div className="tokonoimcs-box">
                  <div className="tokonoimcs-text">Total Supply</div>
                  <div className="tokonoimcs-text2">100M</div>
                  <div className="tokonoimcs-button">
                    <h5>Supply</h5>
                    <p>100M Supply 100% Tokens in Circulation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="tokonoimcs-box">
                  <div className="tokonoimcs-text">Buy/Sell Tax</div>
                  <div className="tokonoimcs-text2">5%</div>
                  <div className="tokonoimcs-button">
                    <h5>Taxes</h5>
                    <p>Development, Marketing, Listings,
                      Buybacks, Partnerships</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="tokonoimcs-box">
                  <div className="tokonoimcs-text">Max Wallet</div>
                  <div className="tokonoimcs-text2">5%</div>
                  <div className="tokonoimcs-button">
                    <h4>Contract Renounced</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="tokonoimcs-box">
                  <div className="tokonoimcs-text">Transfer</div>
                  <div className="tokonoimcs-text2">0%</div>
                  <div className="tokonoimcs-button">
                    <h5>Lock</h5>
                    <p>1 Year Liquidity Locked</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-center" >

              <div className="col-lg-5 d-flex justify-content-end">
                <div className="col-lg-11" style={{ width: '100%'}}>
                  <div className="contract-address-1 pdgftd space-between" style={{display: "flex"}}>
                    <div className="contract-address-2 jhgtd contract-address-text" style={{ paddingTop: "5px"}}>$KolsAI - 0xA60F743ff20aFefA0a6B72D3f6FEC075B3652598</div>
                    <div className="contract-address-5 contract-address-copy">
                      <img className="contract-address-4" src={copyicon} alt="" onClick={() => handleCopy("0xA60F743ff20aFefA0a6B72D3f6FEC075B3652598")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4">
                    <a
                      href="https://etherscan.io/address/0xa60f743ff20afefa0a6b72d3f6fec075b3652598"
                      target="_blank"
                    >
                      <div className="contract-address-1 gftyh">
                        <img className="contract-address-3" src={contactaddress1} alt="" />
                        <div className="contract-address-2 center-class">EthScan</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4">
                    <a
                      href="https://www.dextools.io/app/en/ether/pair-explorer/0x8a09755f8eb067e689dd960422a279c99fbc18f4?t=1732734675250"
                      target="_blank"
                    >
                      <div className="contract-address-1 gftyh">
                        <img className="contract-address-3" src={contactaddress2} alt="" />
                        <div className="contract-address-2 center-class">DexTools</div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4">
                    <a
                      href="https://app.uniswap.org/explore/tokens/ethereum/0xa60f743ff20afefa0a6b72d3f6fec075b3652598"
                      target="_blank"
                    >
                      <div className="contract-address-1 gftyh">
                        <img className="contract-address-3" src={contactaddress3} alt="" />
                        <div className="contract-address-2 center-class">UniSwap</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- TESTIMONIALS SECTION --> */}
      <section
        className="testimonials_section"
        style={{ paddingBottom: "150px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="testimonials_content">
                <h6>Our Industry Leaders</h6>
                <h2 className="real-time-heading">Integration Partner</h2>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container">
              <div className="row" data-aos="fade-up">
                <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none"></div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                  <a href="https://dexscreener.com/" target="_blank" rel="noreferrer">
                    <figure className="logo_image1 mb-2">
                      <img
                        src={DexLogo1}
                        alt=""
                        className="img-fluid hover-effect partner-images"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                  <a href="https://t.me/SafeAnalyzerbot" target="_blank" rel="noreferrer">
                    <figure className="logo_image2 mb-2">
                      <img
                        src={DexLogo2}
                        alt=""
                        className="img-fluid hover-effect partner-images"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                  <a href="https://maestrobots.com/" target="_blank" rel="noreferrer">
                    <figure className="logo_image3 mb-2">
                      <img
                        src={DexLogo3}
                        alt=""
                        className="img-fluid hover-effect border-radius-25"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                  <a href="https://t.me/GeekSwapNews" target="_blank" rel="noreferrer">
                    <figure className="logo_image4 mb-2">
                      <img
                        src={DexLogo4}
                        alt=""
                        className="img-fluid hover-effect border-radius-25"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                  <a href="https://www.dextools.io/" target="_blank" rel="noreferrer">
                    <figure className="logo_image5 mb-2">
                      <img
                        src={DexLogo5}
                        alt=""
                        className="img-fluid hover-effect partner-images"
                      />
                    </figure>
                  </a>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- COMBO SECTION --> */}

      <section className="">
        {/* <!-- GET STARTED SECTION --> */}
        <div className="get_started_section">
          <div className="container">
            <div className="get_started_box" style={{ marginTop: "-105px" }}>
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 col-12 order-md-2 order-2">
                  <div className="get_started_box_image">
                    <figure className="mb-0 left_right_shape">
                      <img
                        src={getstartedrobot}
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 col-12 order-md-1 order-1">
                  <div
                    className="get_started_box_content"
                    data-aos="fade-right"
                  >
                    <h2 className="text-white get_started_heading">
                      Let's Make Something <br /> Great Together
                    </h2>
                    <a
                      className="text-decoration-none default-btn btn-size-18 hover-effect readmore_btn"
                      href="https://app.kolsai.io/"
                      target="_blank" rel="noreferrer"
                    >
                      Get Started <FaArrowRight />
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* <!-- FOOTER SECTION --> */}
    </div>
  );
};
