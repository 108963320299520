import React from "react";
import "./roadmap.css";
import { MdOutlineDoneOutline } from "react-icons/md";
import star1 from "../../images/star1.png";
import star2 from "../../images/star3.png";
import roadmapImage from "../../images/rodamap-image.png";
import tickIcon from "../../images/correct-icon.svg";

export const RoadmapMain = () => {

  return (
    <>
      <section className="roadmap position-relative zindex-1" id="roadmap">
        <div className="transition-gradient-after adjust-top"></div>
        <div className="container">
          <div className="dots-vert"></div>
          <div className="feature_content text-center" data-aos="fade-right">
            <h6>Roadmap</h6>
            <h2>Building the Future of Crypto Trading</h2>
          </div>

          <div className="row mt-20 mb-50">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="block-1">
                    <h4 className="roadmap-inner-heading"> <span className="special-color">Q3 2024</span> : Platform Launch & Core Feature Rollout</h4>

                    <ul className="list-unstyled mb-0">
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        Website Launch
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        beta App development Started
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        Blockchain Data integration
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        Tweeter and Telegram Data integration
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="block-2">
                    <h4 className="roadmap-inner-heading"> <span className="special-color">Q4 2024</span> : $KolsAI Token Launch & Expansion</h4>

                    <ul className="list-unstyled mb-0">
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        beta app testing started
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        Whitepaper and Roadmap update
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        App launch for public
                        </p>
                      </li>
                      <li>
                        <img src={tickIcon} alt="" className="roadmap-tick"/>
                        <p className="roadmap-text">
                        $KolsAI Token launch

                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="block-3">
                    <h4 className="roadmap-inner-heading"> <span className="special-color">Q1 2025</span> : Global Adoption & <br /> Marketing Push</h4>
                    
                    <ul className="list-unstyled mb-0">
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        International Marketing Campaigns
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Auto Buy/Sell
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Influencer Monetization
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="img-container">
                <img src={roadmapImage} alt="" className="img-fluid" />
              </div>

              <div className="row">
                <div className="col-lg-4">
                  <div className="block-4">
                    <h4 className="roadmap-inner-heading"> <span className="special-color">Q2 2025</span> : Full AI Module & <br />Advanced Analytics</h4> 

                    <ul className="list-unstyled mb-0">
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        AI-Powered Market Insights
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Expanded Influencer Network
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Mobile App Development
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        AI-Driven Predictions
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="block-5">
                    <h4 className="roadmap-inner-heading"> <span className="special-color">Q3 2025</span> : Decentralization & Governance</h4>
                    
                    <ul className="list-unstyled mb-0">
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        DAO Integration
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Platform Enhancements 
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Advanced AI Evolution
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="block-6">
                    <h4 className="roadmap-inner-heading mt-10">Vision <span className="special-color"> Q4 2025</span> and Beyond</h4>
                    
                    <ul className="list-unstyled mb-0">
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Mass Adoption
                        </p>
                      </li>
                      <li>
                        {/* <img src={tickIcon} alt="" className="roadmap-tick"/> */}
                        <p className="roadmap-text">
                        Interoperability
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
            <img
            src={star1}
            alt=""
            className="img-fluid w-50"
            style={{ opacity: 0.2 }}
            />
        </figure>
        <figure
            className="banner_right_shape mb-0 position-absolute"
            style={{ width: "24%" }}
        >
            <img
            src={star2}
            alt=""
            className="img-fluid"
            style={{ opacity: 0.2 }}
            />
        </figure>
    </>
  );
};
