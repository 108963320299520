import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'es6-promise/auto';

const userAgent = navigator.userAgent || navigator.vendor || window.opera;



if (userAgent.includes("Telegram")) {
  // Redirect to an external browser
  alert(
    "It looks like you're opening this website in Telegram's in-app browser. For a better experience, please open it in an external browser."
  );



  if (/android/i.test(userAgent)) {
    window.location.href = `intent://${window.location.host}${window.location.pathname}#Intent;scheme=https;end;`;
  } else {
    // Fallback for iOS: Provide a manual option
    document.body.innerHTML = `
      <div style="text-align: center; padding: 20px;">
        <h1>Open in External Browser</h1>
        <p>Please copy the link below and open it in Safari or another browser:</p>
        <input type="text" value="${window.location.href}" style="width: 90%; padding: 10px;" readonly />
      </div>
    `;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

if (!window.Promise) {
  // Add a polyfill or use a library like 'es6-promise'
  require('es6-promise').polyfill();
}

if (!window.fetch) {
  // Use a fetch polyfill library
  require('whatwg-fetch');
}


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
