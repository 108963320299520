import logo from './logo.svg';
import './App.css';
import { Home } from './Pages/Home';
import { Header } from './Components/Header';
import { Footer } from './Components/Footer';
import { AllRoutes } from './Routes/AllRoutes';
import { BrowserRouter } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
